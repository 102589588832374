$namespace: 'InspitripACP';

* { -webkit-overflow-scrolling: touch; }
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
}
input[type='text'] { height: 34px; padding: 6px 12px; font-size: 14px; line-height: 1.42857143;
  color: #555; background-color: #fff; background-image: none; border: 1px solid #d2d6de;
}
.logo {
  // height: 32px;
  margin: 0 12px;
  font-size: 20pt;
}

input[type='text']:focus { border-color: #3c8dbc; }
button, input { outline: none !important; }
.btn { padding: 6px 12px !important; font-size: 12px !important;}
.form-group.noMargin { margin-bottom: 0; }
.form-control {height: 34px !important;}
textarea.form-control {height: 80px !important;}
.dropdown-menu { right: 0; left: auto; }
.dropdown-menu .divider { margin: 0; }
.dropdown-menu > li > a { padding: 8px 15px; cursor: pointer; }
.nav-stacked > li > a { border: none; }
.box-header, .box-body { padding: 12px 15px 10px 15px; }
.label.bg-gray { color: #fff !important; background-color: #b7b7b7 !important; }
.bg-yellow { background: #f3c312 !important; }
.timeline>li>.fa { width: 16px; height: 16px; top: 9px; left: 25px; background: #a2a6ad !important; }
.timeline>li .avatar { position: absolute !important; left: 14px; top: 3px; }
.timeline>li .avatar img { width: 40px !important; height: 40px !important; }
.table-responsive { -webkit-overflow-scrolling: touch; }
.content-wrapper { min-height: 850px !important; }
.overview-wrapper { min-height: 850px !important; }
.nav-tabs-custom>.tab-content { padding: 15px; }
.react-datepicker { min-width: 200px; }
.react-datepicker .react-datepicker__header { background: none; border: none; }
.react-datepicker._inline { display: inline-block; border-radius: 3px; background: #ffffff; border: 1px solid #ddd; border-top: 3px solid #3c8dbc; margin-bottom: 20px; box-shadow: 0 1px 1px rgba(0,0,0,0.1)}
.react-datepicker .react-datepicker__day-names { border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; background: #f9f9f9; }
.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day { height: 25px; width: 25px; line-height: 25px; }
.react-datepicker .react-datepicker__navigation { border-width: 7px; }
.react-datepicker._big-size .react-datepicker__navigation { border-width: 10px; }
.react-datepicker__navigation--next { right: 0; border-left-color: #aaa; }
.react-datepicker__navigation--previous { left: 0; border-right-color: #aaa; }
.react-datepicker, .react-datepicker__current-month { font-size: 13px; }
.react-datepicker._big-size .react-datepicker__day-name,
.react-datepicker._big-size .react-datepicker__day { height: 36px; width: 36px; font-size: 15px; line-height: 36px; margin: 0; }
.react-datepicker .react-datepicker__current-month { font-size: 15px; padding-bottom: 8px; }
.react-datepicker._big-size .react-datepicker__current-month { font-size: 22px; font-weight: 500; }
.react-datepicker__day-name { font-weight: bold; }
.react-datepicker._big-size .react-datepicker__day-name { font-size: 12px; }
.box-body.no-padding-tb { padding-top: 0; padding-bottom: 0; }
.react-datepicker__day--highlighted-block { background: #FEA; }
.react-datepicker__day--highlighted-availability { background: #8ad088; }
.dataTable tbody button.btn { font-size: 8px !important; padding: 3px 8px !important; }